import './App.scss';
import React from 'react';
import Web3 from 'web3'; 
import { chainMap, enforceChain } from './tools/ChainTools.js'
import { tokens } from './configs/CultTokensConfig.js'
import { nfts } from './configs/CultNFTConfig.js'
import { degenesis, dialog, story } from './configs/CandleDegenesisConfig.js'

//import door from './imgs/door.gif'
//import famgif from './imgs/fam.gif'

import briefcase from './images/briefcase.png'
//import famgif from './imgs/famslow.gif'

//import door from './imgs/doorslowbig.gif'
import famgif from './imgs/famsmooth.gif'

import dustimg from './images/dust.png'
import karmacrystal from './images/karmacrystal.jpg'
import karmaimg from './images/karma.jpg'
import famcrystal from './images/famcrystal.jpg'
import figure from './imgs/figure.gif'

import edenEntryway from './images/edenEntryway.gif'
import storefront from './images/store-front.gif'


import record from './images/record.png'
import hopium from './images/hopium.png'
import pickaxe from './images/pickaxe.png'
import goggles from './images/goggles.jpg'
import pipe from './images/pipe.png'
import lantern from './images/lantern.png'

import apple from './images/apple.png'

import iconX from './images/icon--twitter.png'
import iconTelegram from './images/icon--telegram.png'
import iconDiscord from './images/discord.png'

import edenForge from './images/edenForge.png'

import rock from './images/rock.png'
import chalk from './images/chalk.png'

import rest from './images/sleepyFamiliar.gif'
import chalkMachine from './images/chalkMachine.jpg'

import pending from './images/pending.gif'
import sound__keychain from './sounds/keychain.wav'

import sound__pageOne from './sounds/pageOne.wav'
import sound__pageTwo from './sounds/pageTwo.wav'


import sound__menuMusic3 from './sounds/menuMusic3.mp3'
import sound__adopt from './sounds/adopt.wav'
import sound__click from './sounds/click.wav'
import sound__gameOver from './sounds/gameOver.wav'

import sound__crystal from './sounds/crystal.wav'
import sound__mining from './sounds/mining.wav'

import sound__hover from './sounds/hover.wav'


const fs = (bal, dec = 18) => {
  let x = bal.toString()
  let digits, decimals
  let L = "0", R = "0"
  let output
  //console.log(x.length, dec)
  if (x.length > dec) {
    digits = x.length - dec
    L = x.slice(0,digits)
    R = x.slice(digits)
  } else {
    decimals = dec - x.length
    R = "0".repeat(decimals).concat(x)
  }
  output = L.concat(".").concat(R)
  output = new Intl.NumberFormat().format(output)
  return output
}
function App() {
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");

 
  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    enforceChain("Fantom", letItRip)
    setMMBtnText("Connected")
    setIsModalConnectOpen(false)
    setIsMenuOpen(true)
    setIsBypassed(false)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true


  const connectMM = () => {
    
      if (!connected) {

        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      }
      playKeychain()
  }

  
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
        window.ethereum.on('chainChanged', handleChainChange);
      };
    }
  }, [connected]);



  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])



  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  

  const [isDisabled, setDisabled] = React.useState(false);

  // this is a reference to the input field
  const theInputRef = React.createRef();

  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);
  const [pendingText, setPendingText] = React.useState("Pending Transaction");
  const web3 = new Web3(new Web3.providers.HttpProvider('https://rpc.ftm.tools'));





  const letItRip = () => {
    console.log("let it rip")


    goToDialog("start")

   

  }

  const getTransactionReceipt = (hash, callback= ()=>{}) => {
    console.log(hash)
    window.ethereum.request({
      method: "eth_getTransactionReceipt",
      params: [hash]
    }).then((res)=>{
      if (res == null) {
        getTransactionReceipt(hash, callback)
      }
      callback(res)
    }).catch((err)=>{
      console.log(err)
    })
  }

  const [livePage, setLivePage] = React.useState("home");

  const nav = (name) => {
    return () => {
      setLivePage(name);
    }
  }

  const [modalOn, setModalOn] = React.useState(false)
  const [activeModalPage, setActiveModalPage] = React.useState("")

  const setModal = (page) => {
    return () => {
      setModalOn(true)
      setActiveModalPage(page)
    }
  }

 


const [activeDialogIndex, setActiveDialogIndex] = React.useState("start")
const [isDialogOpen, setIsDialogOpen] = React.useState(false)
const [checkedPreOptions, setCheckedPreOptions] = React.useState(true)
const passwordRef = React.createRef()
const goToDialog = (n) => {
  return () => {setActiveDialogIndex(n)}
}

const [isBCopen, setIsBCopen] = React.useState(false)

const [balRock, setBalRock] = React.useState(0)
const [balChalk, setBalChalk] = React.useState(0)
const toggleBC = () => {
  playKeychain()
  if (isBCopen) {
    setIsBCopen(false)
  } else {
    setIsBCopen(true)
    balanceOfItems()
    fam.methods.balanceOf(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=> {
        console.log("token req", res)
        setFamBal(res)
      }).catch((err) => {
        console.log(err)
      })

      getResourceBalance(3, setBalRock)
      getCrystalBalance(11, setBalChalk)
  }
}


const [isMenuOpen, setIsMenuOpen] = React.useState(false)
const [isBypassed, setIsBypassed] = React.useState(false)
const showStory = () => {
  stopMenu()
  playKeychain()
  if (connected || isBypassed) {
    setIsMenuOpen(false)
    setIsLanderOpen(false)
    setIsDialogOpen(true)
  } else {
    alert("Please click Connect first")
  }
  
}

const [isModalConnectOpen, setIsModalConnectOpen] = React.useState(false)
const beginConnectFlow = () => {
  setIsModalConnectOpen(true)
}

const bypassConnect = () => {
  playKeychain()
  setIsModalConnectOpen(false)
  setIsMenuOpen(true)
  setMMBtnText("Connect")
  setIsBypassed(true)
}

const [isModalForgeOpen, setIsModalForgeOpen] = React.useState(false)
// ------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------



  var candle = new web3.eth.Contract(
    tokens["candle"]["abi"], 
    tokens["candle"]["address"])

  var resources = new web3.eth.Contract(
    nfts["resources"]["abi"],
    nfts["resources"]["address"]
    )

  const cultItems = new web3.eth.Contract(
  nfts["items"]["abi"],
  nfts["items"]["address"]
  ) 
  const crys = new web3.eth.Contract(
    nfts["crystals"]["abi"], 
    nfts["crystals"]["address"]) 

 // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
const [minted_item1, set_minted_item1] = React.useState(0)
  const [minted_item2, set_minted_item2] = React.useState(0)
  const [minted_item11, set_minted_item11] = React.useState(0)
  const [minted_item12, set_minted_item12] = React.useState(0)
  const [minted_item13, set_minted_item13] = React.useState(0)
  const [minted_item21, set_minted_item21] = React.useState(0)
  const [minted_item22, set_minted_item22] = React.useState(0)
  const [minted_item31, set_minted_item31] = React.useState(0)

  const [minted_item101, set_minted_item101] = React.useState(0)
  const [minted_item102, set_minted_item102] = React.useState(0) 
  const [minted_item103, set_minted_item103] = React.useState(0)
  const [minted_item104, set_minted_item104] = React.useState(0)
  const [minted_item105, set_minted_item105] = React.useState(0)
  const [minted_item106, set_minted_item106] = React.useState(0)

  
    const getMintedAmount = (id, setState) => {
   cultItems.methods.getItem(id)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        console.log(res["6"])
        setState(res["6"])
      }) 
  }

const approveCandle4Shop = (amt = "5000000000000000000000") => {
    let data = candle.methods.approve(nfts["items"]["address"], amt).encodeABI()
    window.ethereum.request(
    {
      method: "eth_sendTransaction",
      params: [
      {
        from: window.ethereum.selectedAddress,
        to: tokens["candle"]["address"],
        value: 0,
        data: data,
      },
      ],
    })
  
}

  
  const mint1155transaction = (itemId, cost, callback = () => {}) => {
  //  alert("You are now minting an item for "+ cost +" $CANDLE.")
      setIsPending(true)
      setPendingText("Minting an item for "+ cost + " $CANDLE.")

      let data = cultItems.methods.mintItem(itemId).encodeABI()

      window.ethereum.request(
      {
        method: "eth_sendTransaction",
        params: [
        {
          from: window.ethereum.selectedAddress,
          to: nfts["items"]["address"],
          value: 0,
          data: data,
        },
        ],
        
      }).then((res)=>{
        callback(res)
   
      }).catch((error) => {
        setPendingText("Mint transaction failed")
      })
  }

  const mint1155ingame = (itemId, cost, callback = () => {}) => {
    candle.methods.balanceOf(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
      
        if(Number(fs(res).replace(",","")) >= Number(cost)) {

          candle.methods.allowance(
            window.ethereum.selectedAddress,
            nfts["items"]["address"]
            ).call({from:window.ethereum.selectedAddress})
             .then((res)=> {
              if (Number(fs(res).replace(",","")) >= Number(cost)) {
                mint1155transaction(itemId,cost,
                  (hash) => {
                    getTransactionReceipt(hash, (receipt)=>{
                      if (receipt != null) {
                        callback(receipt)
                        setIsPending(false)
                      }
                    })
                  }
                  )
              } else {
                alert("In order to continue to mint, you must approve the spending of " + cost + " $CANDLE")
                setIsPending(true)
                setPendingText("Approving the shop to spend "+ cost + " $CANDLE.")
                let data = candle.methods.approve(nfts["items"]["address"], cost + "000000000000000000").encodeABI()
                window.ethereum.request({
                  method: "eth_sendTransaction",
                  params: [
                  {
                    from: window.ethereum.selectedAddress,
                    to: tokens["candle"]["address"],
                    value: 0,
                    data: data,
                  },
                  ],
                }).then((res)=>{
                  getTransactionReceipt(res,(receipt) => {
                    if (receipt != null) {
                        callback(receipt)
                        mint1155transaction(itemId,cost,(hash)=>{
                          getTransactionReceipt(hash,(receipt)=>{
                            if (receipt != null) {
                              callback(receipt)
                              setIsPending(false)
                            }
                          })
                        })
                      }
                  })
                  
                }).catch((error) => {
                    setPendingText("Approval transaction failed")
                  })
              }
             })
           } else { // user needs to buy candle
            alert("You do not have enough $CANDLE to purchase this item. Visit spooky.fi and add 0x208234F4f8B1bBEA59cfDc38666141654e851DCe as a custom token to purchase.")
           }
      }) 
  }

  const mint1155 = (itemId, cost = "5000", callback = () => {}) => {
    return () => {
      playKeychain()
      mint1155ingame(itemId, cost, callback)
    }
  }



  const [isStoreOpen, setIsStoreOpen] = React.useState(false)
const toggleStore = () => {
  console.log("toggling store", (isStoreOpen) ? "off" : "on")
  if (isStoreOpen) {
    setIsStoreOpen(false)
    setIsDialogOpen(true)
    console.log("store is toggled off")
  } else {
    setIsStoreOpen(true)
    setIsDialogOpen(false)
    console.log("store is toggled on")
    console.log("and the state is ", isStoreOpen)
  }
}

  React.useEffect(()=>{
    if (isStoreOpen) {
      getMintedAmount(1, set_minted_item1)
      getMintedAmount(2, set_minted_item2)
      getMintedAmount(11, set_minted_item11)
      getMintedAmount(12, set_minted_item12)
      getMintedAmount(13, set_minted_item13)
      getMintedAmount(21, set_minted_item21)
      getMintedAmount(22, set_minted_item22)
      getMintedAmount(31, set_minted_item31)

      getMintedAmount(101, set_minted_item101)
      getMintedAmount(102, set_minted_item102)
      getMintedAmount(103, set_minted_item103)
      getMintedAmount(104, set_minted_item104)
      getMintedAmount(105, set_minted_item105)
      getMintedAmount(106, set_minted_item106)

    }
  },[isStoreOpen])

  const showShop = () => {
    if (connected) {
      playKeychain()
      setIsStoreOpen(true)
      setIsMenuOpen(false)
      setIsDialogOpen(false)
      setIsLanderOpen(false)
    } else {
      alert("The shop is a web3 feature, please connect your wallet.")
    }
  }

  const closeShop = () => {
    if (connected) {
      playKeychain()
      setIsStoreOpen(false)
      setIsMenuOpen(true)
      setIsLanderOpen(true)
      setIsDialogOpen(false)
    }
  }

  const showMenu = () => {

    playKeychain()
     playMenu()
    setIsMenuOpen(true)
    setIsLanderOpen(true)
    setIsDialogOpen(false)
    setIsStoreOpen(false)
  }

  const [isLanderOpen, setIsLanderOpen] = React.useState(true)

  const press2start = () => {
    setIsLanderOpen(true)
    playKeychain()
    beginConnectFlow()
    playMenu()
  }

  const goBack =(x) => {

    return () => {
      playKeychain()
      setActiveDialogIndex(x)
    }
    
  }

  const ifHasImgNameGetIt = () => {
    return (story[activeDialogIndex.toString()].hasOwnProperty("imgname")) ? story[activeDialogIndex.toString()]["imgname"] : "base"
  }

  const ifHasModalGetIt = () => {
    return (story[activeDialogIndex.toString()].hasOwnProperty("modal")) ? story[activeDialogIndex.toString()]["modal"] : "base"
  }

// -----------------------------------------------------------------------------------
// MODALS


  const [isModalMineOpen, setIsModalMineOpen] = React.useState(false)
  const [isModalCraftOpen, setIsModalCraftOpen] = React.useState(false)
  const [isModalAppleOpen, setIsModalAppleOpen] = React.useState(false)
  const [isModalPickaxeOpen, setIsModalPickaxeOpen] = React.useState(false)
  const [isModalMineRestOpen, setIsModalMineRestOpen] = React.useState(false)
  const [isModalPipeOpen, setIsModalPipeOpen] = React.useState(false)
  const [isModalHopiumOpen, setIsModalHopiumOpen] = React.useState(false)

  const [isModalRecordOpen, setIsModalRecordOpen] = React.useState(false)

  const [isModalFamOpen, setIsModalFamOpen] = React.useState(false)

  const showModal = (modalName) => {
    playKeychain()
    if(modalName == "apple") {
      setIsModalAppleOpen(true)
    }

    if(modalName == "pickaxe") {
      setIsModalPickaxeOpen(true)
    }

    if(modalName == "mining") {
      setIsModalMineOpen(true)
    }
    if(modalName == "mineRest") {
      setIsModalMineRestOpen(true)
    }

    if(modalName == "craft") {
      setIsModalCraftOpen(true)
    }

    if(modalName == "pipe") {
      setIsModalPipeOpen(true)
    }
    if(modalName == "hopium") {
      setIsModalHopiumOpen(true)
    }

    if(modalName == "record") {
      setIsModalRecordOpen(true)
    }

    if(modalName == "fam") {
      playAdopt()
      setIsModalFamOpen(true)
    }
  }

 
  const lastMinedResource = (callback = () => {}) =>{
    resources.methods.lastMinedResource(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        callback(res)
      }).catch((err)=>{
        console.log(err)
      })
  }
const startMiningBlock = (callback = () => {}) => {
    resources.methods.startMiningBlock(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        callback(res)
      }).catch((err)=>{
        console.log(err)
      })
  }



  const stopMiningBlock = (callback = () => {}) => {
    resources.methods.stopMiningBlock(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
          callback(res)
      }).catch((err)=>{
        console.log(err)
      })
  }


  const getCurrentBlock = (callback = () => {}) => {
    web3.eth.getBlockNumber()
    .then((res)=>{
      console.log(res)
      callback(res)
    }).catch((err)=>{
      console.log(err)
    })
  }


  const restTimes = [0,10000,0,1]
  const miningTimes = [0,1,1,1]

  const checkMiningStatus = (callback = ()=>{}) => {
    startMiningBlock((startBlock) => {
      if (startBlock == 0) { // user is resting
        stopMiningBlock((stopBlock)=> {
          
          getCurrentBlock((currentBlock) => { 
   
            lastMinedResource((resourceID)=> {
      
              if (Number(stopBlock) + restTimes[Number(resourceID)] <= Number(currentBlock)) {

                callback()

                
              } else {
                let d = Number(stopBlock) + restTimes[Number(resourceID)] - Number(currentBlock) 
                alert("You are still resting from your last mining session. Please wait " + d + " more blocks.")
                soundRef__mining.current.pause()
              }
            })
          })
        })
      } else { // user is mining
        // tell the user to rest first
        alert("You are mining. Please rest before attempting to mine again.")
        soundRef__mining.current.pause()
      } 
    })

  }

  const mineRockTransaction = () => {
    let data = resources.methods.mine(3).encodeABI()
      setIsPending(true)
      setPendingText("Mining Rock")
      window.ethereum.request(
        {
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,  
            to: nfts["resources"]["address"],
            value: 0,
            data: data,
          },
        ],
      }

        ).then((hash)=>{
          getTransactionReceipt(hash, (receipt)=>{
            if (receipt != null) {
              soundRef__mining.current.pause()
              setIsPending(false)
            }
          })
          
        }).catch((err)=>{
          setPendingText("Failed to mine rock")
          soundRef__mining.current.pause()
          console.log(err)
        })
  }

  const mineRock = () => {
    playMining()
    setActiveDialogIndex("Mining0")
    setIsModalMineOpen(false)

    // get the lastMinedResource for the user
    // get the stopMiningBlock for the user

    checkMiningStatus(mineRockTransaction)
    


    
  }

  const bypassMine = () => {
    playKeychain()
    setIsModalMineOpen(false)
    setActiveDialogIndex("Mining0")
  }

  const mineRest = () => {
    playKeychain()
    setIsModalMineRestOpen(false)
    setActiveDialogIndex("Mines1")

     let data = resources.methods.rest().encodeABI()
     setIsPending(true)
      setPendingText("Resting (this will mint your Rocks)")
    window.ethereum.request(
      {
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["resources"]["address"],
            value: 0,
            data: data,
          },
        ],
      }
    ).then((hash)=>{
     
          getTransactionReceipt(hash, (receipt)=>{
            if (receipt != null) {
             
              setIsPending(false)
            }
          })
        }).catch((err)=>{
          setIsPending(false)
          console.log(err)
        })
  }

  const bypassRest = () => {
    setIsModalMineRestOpen(false)
    setActiveDialogIndex("Mines1")
    playKeychain()
  }

  const craftChalk = () => {
    setIsModalCraftOpen(false)
    setActiveDialogIndex("Cottage4") 
    playCrystal()
    resources.methods.isApprovedForAll(
      window.ethereum.selectedAddress,
      nfts["crystals"]["address"]
      ).call({from: window.ethereum.selectedAddress})
       .then((res)=>{
        if (res) {
          let data = crys.methods.forge(11, 1).encodeABI()
          setIsPending(true)
          setPendingText("Crafting Chalk")
          window.ethereum.request({
            method: "eth_sendTransaction",
            params: [
              {
                from: window.ethereum.selectedAddress,
                to: nfts["crystals"]["address"],
                value: 0,
                data: data,
              },

            ],
        
          }).then((hash)=>{
            getTransactionReceipt(hash, (receipt)=>{
              if (receipt != null) {
                setIsPending(false)
              }
            })

          }).catch((err)=>{
            setPendingText("Forging failed")
          })
        } else {
          let approveReq = resources.methods.setApprovalForAll(nfts["crystals"]["address"], true).encodeABI()
          alert("You need to approve the use of your cult resources to mint Ritual Chalk.")
          setIsPending(true)
          setPendingText("Approving the use of your cult resources")
          window.ethereum.request(
      {
        method: "eth_sendTransaction",
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: nfts["resources"]["address"],
            value: 0,
            data: approveReq,
          },

        ],
    
      }
    ).then((hash)=>{

      getTransactionReceipt(hash, (receipt)=>{
            if (receipt != null) {
              let data = crys.methods.forge(11, 1).encodeABI()
              setIsPending(true)
              setPendingText("Crafting Chalk")
              window.ethereum.request({
                method: "eth_sendTransaction",
                params: [
                  {
                    from: window.ethereum.selectedAddress,
                    to: nfts["crystals"]["address"],
                    value: 0,
                    data: data,
                  },
                ],
            
              }).then((res)=>{
                getTransactionReceipt(res, (receipt)=>{
                  if (receipt != null) {
                    setIsPending(false)
                  }
                })

              }).catch((err)=>{
                setPendingText("Forging failed")
              })
            }
          })
      
        
      }).catch((err)=>{
          setPendingText("Approval failed")
        })
        }
       })
    
    
      
    
     
  }

  const bypassCraft = () => {
    playKeychain()
    setIsModalCraftOpen(false)
    setActiveDialogIndex("Cottage4") 
  }


  const mintApple = () => {
    playKeychain()
    setIsModalAppleOpen(false)
    setActiveDialogIndex("Bar2")

    
    mint1155ingame(1, "1")
  }
  const bypassApple = () => {
    playKeychain()
    setIsModalAppleOpen(false)
    setActiveDialogIndex("Bar2")
  }

  const mintPickaxe = () => {
    playKeychain()
    setIsModalPickaxeOpen(false)
    setActiveDialogIndex("Mining")

    mint1155ingame(103, "1")
  }
  const bypassPickaxe = () => {
    playKeychain()
    setIsModalPickaxeOpen(false)
    setActiveDialogIndex("Mining")
  }

  const mintPipe = () => {
    playKeychain()
    setIsModalPipeOpen(false)
    setActiveDialogIndex("Mines2")

    mint1155ingame(105, "1")
  }
  const bypassPipe = () => {
    playKeychain()
    setIsModalPipeOpen(false)
    setActiveDialogIndex("Mines2")
  }

  const mintHopium = () => {
    playKeychain()
    setIsModalHopiumOpen(false)
    setActiveDialogIndex("Mines5")

    mint1155ingame(104, "50")
  }
  const bypassHopium = () => {
    playKeychain()
    setIsModalHopiumOpen(false)
    setActiveDialogIndex("Mines5")
  }

  const mintRecord = () => {
    playKeychain()
    setIsModalRecordOpen(false)
    setActiveDialogIndex("Jukebox1")

    mint1155ingame(101, "10")
  }
  const bypassRecord = () => {
    playKeychain()
    setIsModalRecordOpen(false)
    setActiveDialogIndex("Jukebox1")
  }


  const adoptFam = () => {
    playKeychain()
    setIsModalFamOpen(false)
    setActiveDialogIndex("TP")

    // logic for purchasing FAM on spooky goes here 
  }
  const bypassFam = () => {
    playKeychain()
    setIsModalFamOpen(false)
    setActiveDialogIndex("TP")
  }




// -----------------------------------------------------------------------------------
  const locOfColon = (str) => {
    return str.indexOf(":")
  }

  const replaceSpeakerWithStrongSpeaker = (str) => {
    let i = locOfColon(str)
   
    if (i == -1) {
      return str
    } else {
      return (
        <span>
          <strong>{str.substring(0,i)}</strong>
          <span>{str.substring(i,str.length)}</span>
        </span>
      )
    }
  }


const showForge = () => {
  crys.methods.balanceOf(
    window.ethereum.selectedAddress,
    11
    ).call({from: window.ethereum.selectedAddress})
  .then((res)=>{
    if (Number(res) > 0) {
      setIsModalForgeOpen(true)
      playKeychain()
    } else {
      alert("Complete the Campaign to access this feature")
    }
  })
  
}

const [itemBals,setItemBals] = React.useState([0,0,0,0,0,0,0])
const balanceOfItems = () => {
  console.log("Fetching item balances")
  cultItems.methods.balanceOfBatch(
    [
      window.ethereum.selectedAddress, 
      window.ethereum.selectedAddress, 
      window.ethereum.selectedAddress, 
      window.ethereum.selectedAddress, 
      window.ethereum.selectedAddress, 
      window.ethereum.selectedAddress, 
      window.ethereum.selectedAddress],
    [101, 102, 103, 104, 105, 106, 107]
    ).call({from: window.ethereum.selectedAddress})
     .then((res)=>{
      setItemBals(res)
     }).catch((error)=>{
      console.log(error)
     })
}

const getTokenBalance = (token, setState) => {
    token.methods.balanceOf(window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=> {
        console.log("token req", res)
        setState(res)
      }).catch((err) => {
        console.log(err)
      })
  }

  const getResourceBalance = (id, setState) => {
    resources.methods.balanceOf(window.ethereum.selectedAddress, id)
      .call({from: window.ethereum.selectedAddress})
      .then((res) => {
        console.log("resource req", res)
        setState(res)
      }).catch((err)=> {
        console.log(err)
      })
  }

   const [balRitualChalk, setBalRitualChalk] = React.useState(0)
  const getCrystalBalance = (id, setState) => {
    crys.methods.balanceOf(window.ethereum.selectedAddress, id)
      .call({from: window.ethereum.selectedAddress})
      .then((res) => {
        console.log("crystal req", res)
        setState(res)
      }).catch((err)=> {
        console.log(err)
      })
  }

  var fam = new web3.eth.Contract(
    tokens["fam"]["abi"], 
    tokens["fam"]["address"])

  const [famBal, setFamBal] = React.useState(0)


React.useEffect(
() => {
  console.log("itemBals has changed", itemBals)

}, [itemBals]
  )

  const soundRef__keychain = React.useRef(null)
  const soundRef__page = React.useRef(null)
  const soundRef__adopt = React.useRef(null)
  const soundRef__menu = React.useRef(null)
  const soundRef__gameOver = React.useRef(null)
  const soundRef__crystal = React.useRef(null)
  const soundRef__mining = React.useRef(null)
  const soundRef__hover = React.useRef(null)


  const playKeychain = () => {
    soundRef__keychain.current.play()
  }
  const playPageTurn = () => {
    soundRef__page.current.play()
  }

  const playAdopt = () => {
    soundRef__adopt.current.play()
  }

  const playMenu = () => {
    soundRef__menu.current.play()
    soundRef__menu.current.loop = true
    soundRef__menu.current.volume = 0.1
  }
  const stopMenu = () => {
    soundRef__menu.current.pause()
  }

  const playGameOver = () => {
    soundRef__gameOver.current.play()
  }
  const playCrystal = () => {
    soundRef__crystal.current.play()
  }

  const playMining = () => {
    soundRef__mining.current.play()
    soundRef__mining.current.loop = true
  }

  const playHover = () => {
    soundRef__hover.current.pause()
    soundRef__hover.current.play()
    soundRef__hover.current.currentTime = 0
    soundRef__hover.current.volume = 0.2

  }
  const stopHover = () => {
    

  }

const showFam = () => {
  crys.methods.balanceOf(
    window.ethereum.selectedAddress,
    11
    ).call({from: window.ethereum.selectedAddress})
  .then((res)=>{
    if (Number(res) > 0) {
      showModal("fam")
    } else {
      alert("Complete the Campaign to access this feature")
    }
  })
  

}
  

  return (
    <div className="App">
      <div className="the-story"> 
        
    

        <div className={"dialog dialog--" + isDialogOpen}>
        <div className="dialog__active">
          <div className={
            "dialog__speaker dialog__speaker--" + story[activeDialogIndex.toString()]["imgtype"] + 
            " dialog__speaker--" + ifHasImgNameGetIt()
             }>
            <img src={story[activeDialogIndex.toString()]["speaker"]}/>
           
          
          </div>
          <div className={"dialog__text dialog__text--" + story[activeDialogIndex.toString()]["imgtype"]}>
          {
            story[activeDialogIndex.toString()]["text"].map(p=>(<p>{replaceSpeakerWithStrongSpeaker(p)}</p>))
          }
          </div>
          <div className={"dialog__options dialog__options--" + checkedPreOptions}>
          
            {
              (story[activeDialogIndex.toString()].hasOwnProperty("password")) ?
              <div className="password">
                <input ref={passwordRef} defaultValue="password" />
                <div 
                  className="dialog__option" 
                  onClick={() => {
                    if ( story[activeDialogIndex.toString()].password == "DL0" ) {
                       if (passwordRef.current.value == "Do you have the time?") {
                        setActiveDialogIndex(story[activeDialogIndex.toString()]["password"])
                       } else {
                        alert(passwordRef.current.value + " is not the correct password. Please try again.")
                       }
                    } else {
                      if (passwordRef.current.value == "241113") {
                        alert("Correct! Please type 'Who would have known the devil was such a good dancer?' into general chat in the cult discord.")
                        alert("Then type in: 'Not me, $$$' where $$$ must be a 3 digit sequence not used by anyone else. Please scroll through chat to ensure you aren't duplicating another user.")
                        alert("If you followed the instructions will receive a special role and a gift soon!")
                        setActiveDialogIndex(story[activeDialogIndex.toString()]["password"])
                      } else {
                        alert(passwordRef.current.value + " is not the correct password. Please try again.")
                      }
                    }
                    
                  }}>{"> "}Answer
                </div>
              </div> :
              
                story[activeDialogIndex.toString()]["options"].map(
                  (btn,index)=>(
                    <div 
                      key={index}
                      className={
                        "dialog__option dialog__option--" + 
                        activeDialogIndex.toString() + 
                        " dialog__option--" + index }
                      onClick={
                        () => {
                          console.log("clicked")
                          if (story[activeDialogIndex.toString()].hasOwnProperty("modal") && !isBypassed) {
                            showModal(story[activeDialogIndex.toString()]["modal"])
                          } else {
                            if (story[activeDialogIndex.toString()]["optiontarget"][index].includes("GameOver")) {
                              playGameOver()
                            } else {
                               playPageTurn()
                              
                            }
                            setActiveDialogIndex(story[activeDialogIndex.toString()]["optiontarget"][index])      
                          }                 
                        }
                        

                      }>
                      {"> " + btn}
                    </div>
                    ))
              
            
            }
            <div className="progress-bar">
              <div className="progress-bar__inside" style={{width: 2.3*story[activeDialogIndex.toString()]["key"] + '%'}}></div>
            </div>
          </div>
        
        
          <div className="dialog__nav">
            <div className="nav-btn nav-btn--left" onClick={goBack(story[activeDialogIndex.toString()]["back"])}>{"<"} Back</div>
            <div className="nav-btn nav-btn--right" onClick={showMenu}>x Exit</div>
          </div>
        </div>

      </div>
      </div>
      

      

       <div className={"store store-open--" + isStoreOpen}> 
          <div className={"store__img"}><img src={storefront} /></div>
          <div className="store__options">
            <button className="store__option" onClick={mint1155(1,1)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/apple.png" />
              <span>Apple</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{Number(minted_item1)} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(2,1000)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/apple_bite.png" />
              <span>Apple (..bitten)</span>
              <span>1,000 $CANDLE</span>
              <span className="smaller">{Number(minted_item2)} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(11,1)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase_plain.png" />
              <span>Briefcase</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{Number(minted_item11)} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(12,1000)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase.png" />
              <span>Cult Briefcase</span>
              <span>1,000 $CANDLE</span>
              <span className="smaller">{Number(minted_item12)} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(13,3000)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase_bone.png" />
              <span>Bone Handle Briefcase</span>
              <span>3,000 $CANDLE</span>
              <span className="smaller">{Number(minted_item13)} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(21,1)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/key.png" />
              <span>Skeleton Key</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{Number(minted_item21)} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(22,3000)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/key_bone.png" />
              <span>Bone Key</span>
              <span>3,000 $CANDLE</span>
              <span className="smaller">{Number(minted_item22)} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(31,5000)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/matchbook.png" />
              <span>Matchbook</span>
              <span>5,000 $CANDLE</span>
              <span className="smaller">{Number(minted_item31)} / 42 minted</span>
            </button>

            {
              /* 
                  <button className="store__option" onClick={mint1155(101,10)}>
                    <img className="nft-thumb" src={record} />
                    <span>Record</span>
                    <span>10 $CANDLE</span>
                    <span className="smaller">{Number(minted_item101)} / 1000 minted</span>
                  </button>

              */
            }
            
            <button className="store__option" onClick={mint1155(106,1000)} >
              <img className="nft-thumb" src={goggles} />
              <span>Goggles</span>
              <span>1000 $CANDLE</span>
              <span className="smaller">{Number(minted_item106)} / 69 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(103,1)}>
              <img className="nft-thumb" src={pickaxe}  />
              <span>Pickaxe</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{Number(minted_item103)} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(102,100)}>
              <img className="nft-thumb" src={lantern}  />
              <span>Lantern</span>
              <span>100 $CANDLE</span>
              <span className="smaller">{Number(minted_item102)} / 4000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(104,50)}>
              <img className="nft-thumb" src={hopium}  />
              <span>Hopium</span>
              <span>50 $CANDLE</span>
              <span className="smaller">{Number(minted_item104)} / 10000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(105,1)}>
              <img className="nft-thumb" src={pipe}  />
              <span>Pipe</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{Number(minted_item105)} / 1,000,000 minted</span>
            </button>
            <button className="modal__close" onClick={closeShop}>X</button>
          </div>
        </div>

        <div className={"briefcase briefcase--" + isBCopen}>
        <div className="briefcase__title">Inventory</div>
        <div className="briefcase__items">
          <div className="inv inv--items">
            <div className="briefcase__subtitle">Items</div>
            <div className="briefcase__item">
              <img src={record}/>
              <div className="inv-count">({Number(itemBals[0])}) Records</div>
            </div>
            <div className="briefcase__item">
              <img src={lantern}/>
              <div className="inv-count">({Number(itemBals[1])}) Lanterns</div>
            </div>
            <div className="briefcase__item">
              <img src={pickaxe}/>
              <div className="inv-count">({Number(itemBals[2])}) Pickaxes</div>
            </div>
            <div className="briefcase__item">
              <img src={hopium}/>
              <div className="inv-count">({Number(itemBals[3])}) Hopium</div>
            </div>
            <div className="briefcase__item">
              <div><img src={pipe}/></div>
              <div className="inv-count">({Number(itemBals[4])}) Pipes</div>
            </div>
            
            <div className="briefcase__item">
              <img src={goggles}/>
              <div className="inv-count">({Number(itemBals[5])}) Goggles</div>
            </div>
            
            
          </div>
          <div className="inv inv--relics">
            <div className="briefcase__subtitle">Resources & Relics</div>
            <div className="briefcase__item">
              <div><img src={rock}/></div>
               <div className="inv-count">({Number(balRock)}) Rocks</div>
            </div>
            <div className="briefcase__item">
              <div><img src={chalk}/></div>
              <div className="inv-count">({Number(balChalk)}) Chalk</div>
            </div>
           
          </div>
          <div className="familiars">
            <div className="lantern-img"><img src={lantern}/></div>
            <div className="familiar-count">({fs(famBal)}) Familiars</div>
          </div>
          
          
          
          
          
          <button className="modal__close" onClick={toggleBC}>X</button>
        </div>
      </div>



        <div className="header">
          <div className="home">
            <div className="home__img" onClick={toggleBC}><img src={briefcase}/></div>
            
          </div>
        </div>
      


      
      

      <div className={"lander lander--" + isLanderOpen}>
        <div className="lander-image"><img src={edenForge} /></div>

        <div className={"lander-main lander-main--" + isMenuOpen + " lander-main--mobile-" + isModalConnectOpen}>
          <div className="lander-msg sarpanch-bold">Welcome to The Devil's Lounge</div>
          <div className="lander-button"><button onClick={press2start}>Press to Start</button></div>
        </div>

        <div className="lander-social">
          <a href="https://x.com/cultnfts" target="_blank" className="social-link"><img src={iconX}/></a>
          <a href="https://t.me/+azd0MvR8_nZjMTgx" target="_blank" className="social-link"><img src={iconTelegram}/></a>
          <a href="https://discord.gg/qXUppGGnun" target="_blank" className="social-link"><img src={iconDiscord}/></a>
        </div>

        <div className="lander-copyright courier-prime-regular">
          Copyright © CULT NFTs 2024
        </div>

        <div className={"menu-container menu-container--" + isMenuOpen}>
          <div className="menu">
            <div className="menu__title">The Devil's Lounge</div>
            <button className="menu__link" onMouseOver={playHover} onMouseOut={stopHover} onClick={beginConnectFlow}>{mmBtnText}</button>
            <button className="menu__link" onMouseOver={playHover} onMouseOut={stopHover} onClick={showStory}>Campaign</button>
            <button className="menu__link" onMouseOver={playHover} onMouseOut={stopHover} onClick={showShop}>Item Shop</button>
            <button className="menu__link" onMouseOver={playHover} onMouseOut={stopHover} onClick={showForge}>Forge</button>
            <button className="menu__link" onMouseOver={playHover} onMouseOut={stopHover} onClick={showFam}>FamX</button>
            <div className="menu__version courier-prime-regular">Version 1.0.2</div>
          </div>
        </div>

      </div>
     <div className={"modal modal--connect modal--" + isModalConnectOpen}>
        <h2>Connect</h2>
        <div className="modal__inside">
          <p className="hide-on-mobile">This is intended to be a web3 experience on the Fantom network. You can choose to connect your web3 wallet or you can choose to opt out of the web3 experience.</p>
          <p className="hide-on-mobile">Do you have the time?</p>
          <p className="show-on-mobile">Please use desktop to interact with with the web3 features of this application.</p>
          <p className="show-on-mobile">We are working to bring the full web3 experience to mobile users. Select 'Bypass' to start playing the campaign.</p>
          <div className="buttons">
            <button className="hide-on-mobile" onClick={connectMM}>Connect Web3</button>
            <button onClick={bypassConnect}>Bypass</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalConnectOpen(false)
          }

        }>x</button>


      </div>

      <div className={"modal modal--mine modal--" + isModalMineOpen}>
        <h2>Mine Rock</h2>
        <div className="modal__inside">
          
          <p>Begin mining rock.</p>
          <div className="modal__img">
            <img src={rock} />
          </div>
          <div className="buttons">
            <button onClick={mineRock}>Mine</button>
            <button onClick={bypassMine}>Bypass</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalMineOpen(false)
          }

        }>x</button>


      </div>

      <div className={"modal modal--mineRest modal--" + isModalMineRestOpen}>
        <h2>Finish mining rock</h2>
        <div className="modal__inside">
          
          <p>Take a rest.</p>
          <div className="modal__img">
            <img src={rest} />
          </div>
          <div className="buttons">
            <button onClick={mineRest}>Rest</button>
            <button onClick={bypassRest}>Ignore</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalMineRestOpen(false)
          }

        }>x</button>


      </div>


      <div className={"modal modal--craft modal--" + isModalCraftOpen}>
        <h2>Craft Ritual Chalk</h2>
        <div className="modal__inside">
          
          <p>Begin crafting chalk.</p>
          <div className="modal__img">
            <img src={chalkMachine} />
          </div>
          <div className="buttons">
            <button onClick={craftChalk}>Craft</button>
       
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalCraftOpen(false)
          }

        }>x</button>


      </div>

       <div className={"modal modal--apple modal--" + isModalAppleOpen}>
        <h2>Mint an Apple</h2>
        <div className="modal__inside">
          
          <p>This is an optional item.</p>
          <div className="modal__img">
            <img src={apple} />
          </div>
          <div className="buttons">
            <button onClick={mintApple}>Take the Apple</button>
            <button onClick={bypassApple}>Ignore</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalAppleOpen(false)
          }

        }>x</button>


      </div>

      <div className={"modal modal--pickaxe modal--" + isModalPickaxeOpen}>
        <h2>Mint a Pickaxe</h2>
        <div className="modal__inside">
          
          <p>This is an optional collectible item.</p>


          <div className="modal__img">
            <img src={pickaxe} />
          </div>
          <div className="buttons">
            <button onClick={mintPickaxe}>Take the Pickaxe</button>
            <button onClick={bypassPickaxe}>Ignore</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalPickaxeOpen(false)
          }

        }>x</button>


      </div>

      <div className={"modal modal--pipe modal--" + isModalPipeOpen}>
        <h2>Mint a Pipe</h2>
        <div className="modal__inside">
          
          <p>This is an optional collectible item.</p>
          <div className="modal__img">
            <img src={pipe} />
          </div>
          <div className="buttons">
            <button onClick={mintPipe}>Take the Pipe</button>
            <button onClick={bypassPipe}>Ignore</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalPickaxeOpen(false)
          }

        }>x</button>


      </div>

      <div className={"modal modal--hopium modal--" + isModalHopiumOpen}>
        <h2>Mint a jar of Hopium</h2>
        <div className="modal__inside">
          <p>This is an optional collectible item.</p>
          <div className="modal__img">
            <img src={hopium} />
          </div>
          <div className="buttons">
            <button onClick={mintHopium}>Take the Hopium</button>
            <button onClick={bypassHopium}>Ignore</button>
          </div>
        </div>
        <button className="modal__close" onClick={
          ()=>{
            setIsModalHopiumOpen(false)
          }
        }>x</button>
      </div>

      <div className={"modal modal--record modal--" + isModalRecordOpen}>
        <h2>Mint a Record</h2>
        <div className="modal__inside">
        <p>This is an optional collectible item. You will burn 10 $CANDLE when minting.</p>
          <div className="modal__img">
            <img src={record} />
          </div>
          
          <div className="buttons">
            <button onClick={mintRecord}>Mint the Record</button>
            <button onClick={bypassRecord}>Ignore</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalRecordOpen(false)
          }

        }>x</button>


      </div>

      <div className={"modal modal--fam modal--" + isModalFamOpen}>
        <h2>Familiars</h2>
        <div className="modal__inside">
          <p>Familiars are an experimental ERC404 token. You can purchase an identified one from <a href="https://famx.farmgod.finance" target="_blank">FamX</a> or purchase unidentified Familiars from <a href="https://spooky.fi" target="_blank">Spooky</a>.</p>
          {
            // <p>You can also purchase one from the liquidity pool on spooky by pressing the adopt button below.</p>
          }
          <div className="modal__img">
            <img src={rest} />
          </div>
          
          <div className="buttons">
            {
              // <button onClick={adoptFam}>Adopt</button>
            }
            <button onClick={bypassFam}>Ignore</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalFamOpen(false)
          }

        }>x</button>


      </div>

      <div className={"modal modal--forge modal--" + isModalForgeOpen}>
        <h2>Forge</h2>
        <div className="modal__inside">
        <p>If you have completed the Campaign, you should have Ritual Chalk in your possession.</p>
        <p>Users holding Ritual Chalk can use this interface to mine rock and craft chalk.</p> 
         <p>Alternatively you can head to <a href="https://forge.farmgod.finance" target="_blank">The Forge</a></p>
          <div className="buttons">
            <button onClick={mineRock}>Mine Rock</button>
            <button onClick={mineRest}>Rest</button>
            <button onClick={craftChalk}>Craft Chalk</button>
          </div>
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsModalForgeOpen(false)
          }

        }>x</button>


      </div>

      <div className={"modal modal--pending modal--" + isPending}>
        <h2>Pending Transaction</h2>
        <div className="modal__inside">
        <p>{pendingText}</p>
          <div className="modal__img">
            <img src={pending} />
          </div>
          
          
        </div>

        <button className="modal__close" onClick={
          ()=>{
            setIsPending(false)
          }

        }>x</button>

      </div>

      <audio ref={soundRef__keychain}>
        <source src={sound__keychain} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>

      <audio ref={soundRef__page}>
        <source src={sound__click} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>

      <audio ref={soundRef__adopt}>
        <source src={sound__adopt} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>

      <audio ref={soundRef__menu}>
        <source src={sound__menuMusic3} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>

      <audio ref={soundRef__gameOver}>
        <source src={sound__gameOver} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>

      <audio ref={soundRef__crystal}>
        <source src={sound__crystal} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>

      <audio ref={soundRef__mining}>
        <source src={sound__mining} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>

      <audio ref={soundRef__hover}>
        <source src={sound__hover} type="audio/wav" />
        Your browser does not support the audio element.
      </audio>



    </div>
  );
}

export default App;
